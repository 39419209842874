// src/pages/RegisterPage.jsx
import React from 'react';
import RegisterForm from './Components/RegisterForm';


const RegisterPage = () => {
  return (
    <div>
    <RegisterForm/>
  
    </div>
  );
};

export default RegisterPage;